<template>
  <div :class="$style.deviceDebug">
    <!-- 考勤更新频率 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.notice.Attendance') }}
      </a-col>
      <a-col style="margin-left: 40px">
        <span
          v-if="!postObj['CHECK_IN'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['CHECK_IN'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['CHECK_IN'].edit"
          :class="$style.textBtn"
          @click="postObj['CHECK_IN'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['CHECK_IN'].edit"
          :class="$style.textBtn"
          @click="handleSave('CHECK_IN')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t(
            'hat.managementModal.setting.hint.notice.attendanceBeforeAndAfter ',
          )
        }}</span>
        <a-select
          v-model="postObj['CHECK_IN'].size"
          size="small"
          :disabled="!postObj['CHECK_IN'].edit"
          style="width: 90px; margin: 0 6px 0"
        >
          <a-select-option :value="5">5</a-select-option>
          <a-select-option :value="10">10</a-select-option>
          <a-select-option :value="15">15</a-select-option>
          <a-select-option :value="20">20</a-select-option>
        </a-select>
        <span>{{
          $t('hat.managementModal.setting.hint.notice.tipsThree')
        }}</span>
        <span :class="$style.dsc"
          >（{{ $t('hat.managementModal.setting.hint.notice.tipsTwo') }}）</span
        >
      </a-col>
    </a-row>
    <!-- 待机更新频率 -->
    <a-row :class="$style.row" v-if="detail.type !== 'PLUS'">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.standby') }}
      </a-col>
    </a-row>
    <!-- 待机更新频率-传感器状态 -->
    <a-row :class="[$style.row, $style.block]" v-if="detail.type !== 'PLUS'">
      <a-col :offset="1">
        <span>{{ $t('hat.managementModal.setting.hint.debug.sensor') }}</span>
        <a-select
          v-model="postObj['STANDBY_STATUS'].size"
          size="small"
          :disabled="!postObj['STANDBY_STATUS'].edit"
          :options="timeList"
          style="width: 90px; margin: 0 6px 0"
        />
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px">
        <span
          v-if="!postObj['STANDBY_STATUS'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['STANDBY_STATUS'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['STANDBY_STATUS'].edit"
          :class="$style.textBtn"
          @click="postObj['STANDBY_STATUS'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['STANDBY_STATUS'].edit"
          :class="$style.textBtn"
          @click="handleSave('STANDBY_STATUS')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 待机更新频率-设备定位 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t('hat.managementModal.setting.hint.debug.positioning')
        }}</span>
        <a-select
          v-model="postObj['STANDBY_POSITION'].size"
          size="small"
          :disabled="!postObj['STANDBY_POSITION'].edit"
          :options="timeList"
          style="width: 90px; margin: 0 6px 0"
        />
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px">
        <span
          v-if="!postObj['STANDBY_POSITION'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['STANDBY_POSITION'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['STANDBY_POSITION'].edit"
          :class="$style.textBtn"
          @click="postObj['STANDBY_POSITION'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['STANDBY_POSITION'].edit"
          :class="$style.textBtn"
          @click="handleSave('STANDBY_POSITION')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 开机更新频率 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.power') }}
      </a-col>
    </a-row>
    <!-- 开机更新频率-传感器状态 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{ $t('hat.managementModal.setting.hint.debug.sensor') }}</span>
        <a-select
          v-model="postObj['BOOT_STATUS'].size"
          size="small"
          :disabled="!postObj['BOOT_STATUS'].edit"
          :options="timeList"
          style="width: 90px; margin: 0 6px 0"
        />
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px">
        <span
          v-if="!postObj['BOOT_STATUS'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['BOOT_STATUS'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['BOOT_STATUS'].edit"
          :class="$style.textBtn"
          @click="postObj['BOOT_STATUS'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['BOOT_STATUS'].edit"
          :class="$style.textBtn"
          @click="handleSave('BOOT_STATUS')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 开机更新频率-设备定位 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t('hat.managementModal.setting.hint.debug.positioning')
        }}</span>
        <a-select
          v-model="postObj['BOOT_POSITION'].size"
          size="small"
          :disabled="!postObj['BOOT_POSITION'].edit"
          style="width: 90px; margin: 0 6px 0"
        >
          <a-select-option :value="0">实时</a-select-option>
          <a-select-option :value="1">1{{ $t('unit.m') }}</a-select-option>
          <a-select-option
            v-for="item in timeList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <span>{{ $t('hat.managementModal.setting.hint.debug.perTime') }}</span>
      </a-col>
      <a-col style="margin-left: 10px">
        <span
          v-if="!postObj['BOOT_POSITION'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['BOOT_POSITION'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['BOOT_POSITION'].edit"
          :class="$style.textBtn"
          @click="postObj['BOOT_POSITION'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['BOOT_POSITION'].edit"
          :class="$style.textBtn"
          @click="handleSave('BOOT_POSITION')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <!-- 脱帽消抖时间 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.Uncapping') }}
      </a-col>
      <a-col style="margin-left: 40px">
        <span
          v-if="!postObj['UN_CAP'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['UN_CAP'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['UN_CAP'].edit"
          :class="$style.textBtn"
          @click="postObj['UN_CAP'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['UN_CAP'].edit"
          :class="$style.textBtn"
          @click="handleSave('UN_CAP')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{ $t('hat.managementModal.setting.hint.notice.five') }}</span>
        <a-select
          v-model="postObj['UN_CAP'].size"
          size="small"
          :disabled="!postObj['UN_CAP'].edit"
          style="width: 90px; margin: 0 6px 0"
        >
          <a-select-option :value="1">1{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="5">5{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="10">10{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="15">15{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="20">20{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="30">30{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="45">45{{ $t('unit.s') }}</a-select-option>
          <a-select-option :value="60">60{{ $t('unit.s') }}</a-select-option>
        </a-select>
        <span>{{
          $t('hat.managementModal.setting.hint.notice.tipsfour')
        }}</span>
      </a-col>
    </a-row>
    <!-- 近电传感器灵敏度 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.voltage') }}
      </a-col>
      <a-col style="margin-left: 40px">
        <span
          v-if="!postObj['ELECTROINDUCTION'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['ELECTROINDUCTION'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['ELECTROINDUCTION'].edit"
          :class="$style.textBtn"
          @click="postObj['ELECTROINDUCTION'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['ELECTROINDUCTION'].edit"
          :class="$style.textBtn"
          @click="handleSave('ELECTROINDUCTION')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1">
        <span>{{
          $t('hat.managementModal.setting.hint.debug.sensitivity')
        }}</span>
        <a-select
          v-model="postObj['ELECTROINDUCTION'].size"
          size="small"
          :disabled="!postObj['ELECTROINDUCTION'].edit"
          style="width: 100px; margin: 0 6px 0"
        >
          <a-select-option :value="1">220V</a-select-option>
          <a-select-option :value="2">10KV</a-select-option>
          <a-select-option :value="3">35KV</a-select-option>
          <a-select-option :value="4">110KV</a-select-option>
          <a-select-option :value="5">220KV</a-select-option>
        </a-select>
        <span></span>
      </a-col>
    </a-row>
    <!-- 音量设置 -->
    <a-row :class="$style.row">
      <a-col> 音量设置 </a-col>
      <a-col style="margin-left: 10px">
        <a-select
          v-model="postObj['VOLUME'].size"
          size="small"
          :disabled="!postObj['VOLUME'].edit"
          style="width: 100px; margin: 0 6px 0"
        >
          <a-select-option
            v-for="(item, index) in 5"
            :key="index"
            :value="index + 1"
          >
            {{ index + 1 }}
          </a-select-option>
        </a-select>
        <span
          v-if="!postObj['VOLUME'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['VOLUME'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['VOLUME'].edit"
          :class="$style.textBtn"
          @click="postObj['VOLUME'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['VOLUME'].edit"
          :class="$style.textBtn"
          @click="handleSaveVolume('VOLUME', 'DEVICE_VOLUME')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-col :offset="1" :class="$style.dsc">
        音量由1到5逐步增大，广播和音视频通话音量统一由此控制
      </a-col>
    </a-row>
    <!-- 设备语言 -->
    <a-row
      :class="$style.row"
      v-if="properties && properties.speechRecognition"
    >
      <a-col> 设备语言 </a-col>
      <a-col style="margin-left: 10px">
        <a-select
          v-model="postObj['LANGUAGE'].size"
          size="small"
          :disabled="!postObj['LANGUAGE'].edit"
          style="width: 100px; margin: 0 6px 0"
        >
          <a-select-option
            v-for="item in languages"
            :key="item.key"
            :value="item.value"
          >
            {{ item.key }}
          </a-select-option>
        </a-select>
        <span
          v-if="!postObj['LANGUAGE'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['LANGUAGE'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['LANGUAGE'].edit"
          :class="$style.textBtn"
          @click="postObj['LANGUAGE'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['LANGUAGE'].edit"
          :class="$style.textBtn"
          @click="handleSaveVolume('LANGUAGE', 'VOICE_LANGUAGE')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row
      :class="[$style.row, $style.block]"
      v-if="properties && properties.speechRecognition"
    >
      <a-col :offset="1" :class="$style.dsc">
        注意：多语言需开通语音识别功能，切换后自定义配置的语音提醒内容不会自动切换语言
      </a-col>
    </a-row>
    <!-- 设备近电提醒 -->
    <a-row :class="$style.row">
      <a-col>设备近电提醒</a-col>
      <a-col style="margin-left: 10px">
        <a-select
          v-model="postObj['ELECTRIC_SHOCK'].size"
          size="small"
          :disabled="!postObj['ELECTRIC_SHOCK'].edit"
          style="width: 100px; margin: 0 6px 0"
        >
          <a-select-option :value="1">开启</a-select-option>
          <a-select-option :value="0">关闭</a-select-option>
        </a-select>
        <span
          v-if="!postObj['ELECTRIC_SHOCK'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['ELECTRIC_SHOCK'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['ELECTRIC_SHOCK'].edit"
          :class="$style.textBtn"
          @click="postObj['ELECTRIC_SHOCK'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['ELECTRIC_SHOCK'].edit"
          :class="$style.textBtn"
          @click="handleSaveRemind('ELECTRIC_SHOCK', 'ELECTRIC_SHOCK')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-input
        :class="$style.input"
        v-model="postObj['ELECTRIC_SHOCK'].content"
        v-if="postObj['ELECTRIC_SHOCK'].size"
        :disabled="!postObj['ELECTRIC_SHOCK'].edit"
      />
    </a-row>
    <!-- 设备脱帽提醒 -->
    <a-row :class="$style.row">
      <a-col>设备脱帽提醒</a-col>
      <a-col style="margin-left: 10px">
        <a-select
          v-model="postObj['UN_CAP_REMIND'].size"
          size="small"
          :disabled="!postObj['UN_CAP_REMIND'].edit"
          style="width: 100px; margin: 0 6px 0"
        >
          <a-select-option :value="1">开启</a-select-option>
          <a-select-option :value="0">关闭</a-select-option>
        </a-select>
        <span
          v-if="!postObj['UN_CAP_REMIND'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['UN_CAP_REMIND'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['UN_CAP_REMIND'].edit"
          :class="$style.textBtn"
          @click="postObj['UN_CAP_REMIND'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['UN_CAP_REMIND'].edit"
          :class="$style.textBtn"
          @click="handleSaveRemind('UN_CAP_REMIND', 'UN_CAP')"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.row, $style.block]">
      <a-input
        :class="$style.input"
        v-model="postObj['UN_CAP_REMIND'].content"
        v-if="postObj['UN_CAP_REMIND'].size"
        :disabled="!postObj['UN_CAP_REMIND'].edit"
      />
    </a-row>
    <!-- 信标定位 -->
    <a-row :class="[$style.row, $style.block]">
      <a-col> 信标定位 </a-col>
      <a-col>
        <a-select
          v-model="postObj['BLUETOOTH'].btSwitch"
          size="small"
          :disabled="!postObj['BLUETOOTH'].edit"
          style="width: 100px; margin-left: 16px"
        >
          <a-select-option :value="true">开启</a-select-option>
          <a-select-option :value="false">关闭</a-select-option>
        </a-select>
      </a-col>
      <a-col style="margin-left: 10px">
        <span
          v-if="!postObj['BLUETOOTH'].edit && $p.action('UPDATE')"
          :class="$style.textBtn"
          @click="postObj['BLUETOOTH'].edit = true"
        >
          {{ $t('common.setting') }}
        </span>
        <span
          v-if="postObj['BLUETOOTH'].edit"
          :class="$style.textBtn"
          @click="postObj['BLUETOOTH'].edit = false"
        >
          {{ $t('msg.cancel') }}
        </span>
        <span
          v-if="postObj['BLUETOOTH'].edit"
          :class="$style.textBtn"
          @click="handleSaveBeacon"
        >
          {{ $t('msg.save') }}
        </span>
      </a-col>
    </a-row>
    <template v-if="postObj['BLUETOOTH'].btSwitch">
      <a-row :class="[$style.row, $style.block]">
        <a-col :offset="1">
          <span
            >扫描间隔<a-tooltip>
              <template slot="title">
                仅对三叠自研设备有效，扫描间隔时间越小，相对精度更高，电池续航时间越短
              </template>
              <a-icon
                type="question-circle"
                style="margin: 0 6px"
              /> </a-tooltip
          ></span>
          <a-select
            v-model="postObj['BLUETOOTH'].btInterval"
            size="small"
            :disabled="!postObj['BLUETOOTH'].edit"
            style="width: 100px; margin: 0 6px 0"
          >
            <a-select-option
              :value="item"
              v-for="item in [5, 10, 15, 20, 30, 60]"
              :key="item"
              >{{ item }}秒/次</a-select-option
            >
          </a-select>
        </a-col>
      </a-row>
      <a-row :class="[$style.row, $style.block]">
        <a-col :offset="1">
          <span
            >信号范围<a-tooltip>
              <template slot="title">
                支持多选：<br />
                》强信号（-30到-60dBm），表示设备非常接近，通常在 1 米到 3
                米内<br />
                》中等信号（-61到-80dBm），表示设备处于中等距离，通常为 3 米到
                10 米内<br />
                》弱信号（-81到-100dBm），表示设备已经处于蓝牙通信的边缘，距离可能超过
                10 米
              </template>
              <a-icon
                type="question-circle"
                style="margin: 0 6px"
              /> </a-tooltip
          ></span>
          <a-select
            v-model="postObj['BLUETOOTH'].btSignalStrength"
            size="small"
            :disabled="!postObj['BLUETOOTH'].edit"
            style="width: 200px; margin: 0 6px 0"
          >
            <a-select-option value="STRONG"
              >强信号（-30到-60dBm）</a-select-option
            >
            <a-select-option value="INTERMEDIATE"
              >中等信号（-61到-80dBm）</a-select-option
            >
            <a-select-option value="WEAK"
              >弱信号（-81到-100dBm）</a-select-option
            >
          </a-select>
        </a-col>
      </a-row>
      <a-row :class="[$style.row, $style.block]">
        <a-col :offset="1">
          <span
            >信号基准<a-tooltip>
              <template slot="title">
                安全帽距离蓝牙信标1米处的信号强度数值
              </template>
              <a-icon
                type="question-circle"
                style="margin: 0 6px"
              /> </a-tooltip
          ></span>
          <a-select
            v-model="postObj['BLUETOOTH'].btSignalReference"
            size="small"
            :disabled="!postObj['BLUETOOTH'].edit"
            style="width: 100px; margin: 0 6px 0"
          >
            <a-select-option
              :value="-50 - index"
              v-for="(item, index) in 21"
              :key="index"
              >{{ -50 - index }}</a-select-option
            >
          </a-select>
          <span>dBm</span>
        </a-col>
      </a-row>
      <a-row :class="[$style.row, $style.block]">
        <a-col :offset="1">
          <span
            >环境参数<a-tooltip>
              <template slot="title">
                空旷环境：2左右<br />
                普通办公环境：2.5-3.5，取决于障碍物数量和材料<br />
                复杂室内环境：可能达到4或更高
              </template>
              <a-icon
                type="question-circle"
                style="margin: 0 6px"
              /> </a-tooltip
          ></span>
          <a-select
            v-model="postObj['BLUETOOTH'].btEnvironmentsValue"
            size="small"
            :disabled="!postObj['BLUETOOTH'].edit"
            style="width: 100px; margin: 0 6px 0"
          >
            <a-select-option
              :value="(2 + index * 0.1).toFixed(1)"
              v-for="(item, index) in 21"
              :key="index"
              >{{ (2 + index * 0.1).toFixed(1) }}</a-select-option
            >
          </a-select>
        </a-col>
      </a-row>
    </template>
    <!-- 演示语音上传 -->
    <a-row :class="$style.row">
      <a-col>
        演示语音<a-tooltip>
          <template slot="title">
            上传音频(限mp3)后，点击语音通话时将播放此音频
          </template>
          <a-icon type="question-circle" style="margin: 0 10px" />
        </a-tooltip>
      </a-col>
      <a-upload
        :file-list="audioList"
        :maxCount="1"
        :before-upload="beforeUpload"
        :custom-request="handleUpload"
        @change="handleFileChange($event, 'audio')"
        accept="audio/*"
        style="display: flex"
      >
        <a-button type="link"> 上传 </a-button>
      </a-upload>
    </a-row>
    <a-row :class="[$style.block]">
      <a-col :offset="1" style="display: flex" v-if="detail.audioDemoPath">
        <div
          :class="$style.iconBox"
          @click="handleShowFile(detail.audioDemoPath)"
        >
          <x-icon type="tc-color-file-voice" :class="$style.icon" />
        </div>
        <a-button type="link" @click="handleDelete('audio')"> 删除 </a-button>
      </a-col>
    </a-row>
    <!-- 演示视频上传 -->
    <a-row :class="$style.row">
      <a-col>
        演示视频<a-tooltip>
          <template slot="title">
            上传视频(限mp4/mov/avi)后，点击语音通话时将播放此视频
          </template>
          <a-icon type="question-circle" style="margin: 0 10px" />
        </a-tooltip>
      </a-col>
      <a-upload
        :file-list="videoList"
        :maxCount="1"
        :before-upload="beforeUpload"
        :custom-request="handleUpload"
        @change="handleFileChange($event, 'video')"
        accept="video/*"
        style="display: flex"
      >
        <a-button type="link"> 上传 </a-button>
      </a-upload>
    </a-row>
    <a-row :class="[$style.block]">
      <a-col :offset="1" style="display: flex" v-if="detail.videoDemoPath"
        ><video-thumbnail
          :class="$style.thumbnail"
          basePath="/oss/iot/oss"
          :ossPath="detail.videoDemoPath"
        /><a-button type="link" @click="handleDelete('video')">
          删除
        </a-button></a-col
      >
    </a-row>
    <!-- 重置设备 -->
    <a-row :class="$style.row">
      <a-col>
        {{ $t('hat.managementModal.setting.hint.debug.reset') }}
      </a-col>
      <a-col style="margin-left: 20px">
        <span
          v-if="$p.action('UPDATE')"
          :class="$style.textBtn"
          @click="handleReset"
        >
          {{ $t('common.setting') }}
        </span>
      </a-col>
    </a-row>
    <a-row :class="[$style.block]">
      <a-col :offset="1" :class="$style.dsc">{{
        $t('hat.managementModal.setting.hint.debug.deleteTips')
      }}</a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Inject, InjectReactive } from 'vue-property-decorator';
import {
  resetDevice,
  putFrequency,
  updateDeviceData,
  putRtcDemo,
} from '@/services/smart-hat/device-management';
import { getDeviceProperty } from '@/services/smart-hat/device';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
import { UploadService } from '@triascloud/services';
import { crossStorage } from '@triascloud/cross-storage';
import VideoThumbnail from '@/views/hat/device/manage-model/file/video-thumbnail.vue';
import Preview from '@/views/hat/file/preview/index.vue';

@Component({
  components: {
    VideoThumbnail,
  },
})
export default class DeviceDebug extends Vue {
  @InjectReactive('deviceDetail') detail;
  @Inject('UPDATE_DEVICE_DETAIL') updateDeviceDetail;

  mounted() {
    this.getDeviceInfo();
    this.getDeviceProperties();
  }

  properties = {};
  async getDeviceProperties() {
    this.properties = await getDeviceProperty(this.detail.deviceId);
  }

  timeList = [
    {
      label: '5' + this.$t('unit.m'),
      value: 5,
    },
    {
      label: '10' + this.$t('unit.m'),
      value: 10,
    },
    {
      label: '15' + this.$t('unit.m'),
      value: 15,
    },
    {
      label: '30' + this.$t('unit.m'),
      value: 30,
    },
    {
      label: '60' + this.$t('unit.m'),
      value: 60,
    },
    {
      label: '180' + this.$t('unit.m'),
      value: 180,
    },
    {
      label: '360' + this.$t('unit.m'),
      value: 360,
    },
    {
      label: '720' + this.$t('unit.m'),
      value: 720,
    },
    {
      label: '1440' + this.$t('unit.m'),
      value: 1440,
    },
  ];
  languages = [
    {
      key: '中文',
      value: 0,
    },
    {
      key: '粤语',
      value: 1,
    },
    {
      key: 'English',
      value: 2,
    },
    {
      key: '日语',
      value: 3,
    },
  ];
  postObj = {
    CHECK_IN: {
      size: 5,
      edit: false,
    },
    STANDBY_STATUS: {
      size: 30,
      edit: false,
    },
    STANDBY_POSITION: {
      size: 30,
      edit: false,
    },
    BOOT_STATUS: {
      size: 30,
      edit: false,
    },
    BOOT_POSITION: {
      size: 30,
      edit: false,
    },
    UN_CAP: {
      size: 5,
      edit: false,
    },
    ELECTROINDUCTION: {
      size: 1,
      edit: false,
    },
    VOLUME: {
      size: 1,
      edit: false,
    },
    LANGUAGE: {
      size: 0,
      edit: false,
    },
    ELECTRIC_SHOCK: {
      size: 0,
      edit: false,
      content: '',
    },
    UN_CAP_REMIND: {
      size: 0,
      edit: false,
      content: '',
    },
    BLUETOOTH: {
      edit: false,
      btEnvironmentsValue: 2.0,
      btInterval: 5,
      btSignalReference: -50,
      btSignalStrength: 'STRONG',
      btSwitch: true,
    },
  };
  async handleSave(type) {
    try {
      const data = {
        deviceId: this.detail.deviceId,
        frequency: type,
        size: this.postObj[type].size,
      };
      await putFrequency(data);
      this.$message.success(this.$t('common.SuccessfullySaved'));
      // Object.keys(this.postObj).forEach(key => {
      //   this.postObj[key].edit = false;
      // });
      this.postObj[type].edit = false;
      await this.updateDeviceDetail();
      this.getDeviceInfo();
    } catch {
      return false;
    }
  }
  async handleSaveVolume(type, paramType = 'DEVICE_VOLUME') {
    try {
      const data = {
        deviceId: this.detail.deviceId,
        exData: {
          value: this.postObj[type].size,
        },
        type: paramType,
      };
      await updateDeviceData(data);
      this.$message.success(this.$t('common.SuccessfullySaved'));
      this.postObj[type].edit = false;
      await this.updateDeviceDetail();
      this.getDeviceInfo();
    } catch {
      return false;
    }
  }
  async handleSaveRemind(type, paramType) {
    try {
      const data = {
        deviceId: this.detail.deviceId,
        exData: {
          value: this.postObj[type].content,
          type: this.postObj[type].size,
        },
        type: paramType,
      };
      await updateDeviceData(data);
      this.$message.success(this.$t('common.SuccessfullySaved'));
      this.postObj[type].edit = false;
      await this.updateDeviceDetail();
      this.getDeviceInfo();
    } catch {
      return false;
    }
  }
  async handleSaveBeacon() {
    try {
      const data = {
        bluetooth: {
          btEnvironmentsValue: parseFloat(
            this.postObj['BLUETOOTH'].btEnvironmentsValue,
          ),
          btInterval: this.postObj['BLUETOOTH'].btInterval,
          btSignalReference: this.postObj['BLUETOOTH'].btSignalReference,
          btSignalStrength: this.postObj['BLUETOOTH'].btSignalStrength,
          btSwitch: this.postObj['BLUETOOTH'].btSwitch,
        },
        deviceId: this.detail.deviceId,
        type: 'BLUETOOTH',
      };
      await updateDeviceData(data);
      this.$message.success(this.$t('common.SuccessfullySaved'));
      this.postObj['BLUETOOTH'].edit = false;
      await this.updateDeviceDetail();
      this.getDeviceInfo();
    } catch {
      return false;
    }
  }
  async handleReset() {
    const text = this.$t('hat.managementModal.setting.hint.debug.tipsSeven');
    const tips = this.$t('hat.managementModal.setting.hint.debug.tipsSex');
    await createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>{text}</span>
          </template>
          <span slot="subTxt">{tips}</span>
        </Tip>
      ),
      {
        width: '442px',
        title: this.$t('hat.deviceManagement.operateConfirm'),
        onOk: async () => {
          await resetDevice(this.detail.deviceId);
          this.$message.success(this.$t('common.resetSuccessful') + '!');
          await this.updateDeviceDetail();
          this.getDeviceInfo();
        },
      },
    );
  }
  deviceInfo = {};
  async getDeviceInfo() {
    this.deviceInfo = this.detail;
    this.postObj['CHECK_IN'].size = this.deviceInfo.checkInFrequency;
    this.postObj['UN_CAP'].size = this.deviceInfo.unCapFrequency;
    this.postObj['STANDBY_STATUS'].size = this.deviceInfo.standbyFrequency;
    this.postObj[
      'STANDBY_POSITION'
    ].size = this.deviceInfo.standbyPositionFrequency;
    this.postObj['BOOT_STATUS'].size = this.deviceInfo.bootFrequency;
    this.postObj['BOOT_POSITION'].size = this.deviceInfo.bootPositionFrequency;
    this.postObj[
      'ELECTROINDUCTION'
    ].size = this.deviceInfo.electricityFrequency;
    this.postObj['VOLUME'].size = this.deviceInfo.photoSetting.volume;
    this.postObj['LANGUAGE'].size = this.deviceInfo.photoSetting.voiceLanguage;
    this.postObj['ELECTRIC_SHOCK'].size = this.deviceInfo.photoSetting
      .electricShock
      ? 1
      : 0;
    this.postObj[
      'ELECTRIC_SHOCK'
    ].content = this.deviceInfo.photoSetting.electricShockContent;
    this.postObj['UN_CAP_REMIND'].size = this.deviceInfo.photoSetting.unCap
      ? 1
      : 0;
    this.postObj[
      'UN_CAP_REMIND'
    ].content = this.deviceInfo.photoSetting.unCapContent;
    this.postObj[
      'BLUETOOTH'
    ].btEnvironmentsValue = this.deviceInfo.photoSetting.btEnvironmentsValue.toFixed(
      1,
    );
    this.postObj[
      'BLUETOOTH'
    ].btInterval = this.deviceInfo.photoSetting.btInterval;
    this.postObj[
      'BLUETOOTH'
    ].btSignalReference = this.deviceInfo.photoSetting.btSignalReference;
    this.postObj[
      'BLUETOOTH'
    ].btSignalStrength = this.deviceInfo.photoSetting.btSignalStrength;
    this.postObj['BLUETOOTH'].btSwitch = this.deviceInfo.photoSetting.btSwitch;
  }
  // 文件上传
  videoList = [];
  audioList = [];
  fileType = 'video';
  beforeUpload(file) {
    // 文件最大1024M
    const isLimit = 1024 * 1024 * 1024;
    return new Promise((resolve, reject) => {
      if (!isLimit) {
        this.$message.error('上传失败');
        reject(file);
      } else {
        resolve(file);
      }
    });
  }
  handleFileChange({ fileList }, type) {
    this.fileType = type;
    if (this.fileType === 'video') {
      this.videoList = fileList.slice(-1);
    } else {
      this.audioList = fileList.slice(-1);
    }
  }
  fileUrl = '';
  handleUpload(fileObj) {
    const { file } = fileObj;
    setTimeout(async () => {
      const ossPath = await this.uploadImg(
        this.fileType === 'video' ? this.videoList[0] : this.audioList[0],
      );
      const fileUrl = await this.getFileUrl(ossPath);
      fileObj.onSuccess(ossPath, file);
      this.fileUrl = ossPath;
      const data = {
        audioDemoPath:
          this.fileType === 'audio' ? fileUrl : this.detail.audioDemoPath,
        deviceId: this.detail.deviceId,
        videoDemoPath:
          this.fileType === 'video' ? fileUrl : this.detail.videoDemoPath,
      };
      await putRtcDemo(data);
      await this.updateDeviceDetail();
      this.videoList = [];
      this.audioList = [];
    }, 500);
  }
  async getFileUrl(ossPath) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const fileUrl = await customService.getAuth(ossPath);
    return fileUrl;
  }
  tenantId = JSON.parse(crossStorage.getItem('tenant')).pkId;
  async uploadImg(file) {
    const files = file.originFileObj;
    const uploadPath = `${this.tenantId}/iot/hat/${this.fileType}`;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const ossPath = await customService.upload(
      files,
      uploadPath,
      () => {},
      false,
    );
    return ossPath;
  }
  async handleDelete(type) {
    const data = {
      audioDemoPath: type === 'audio' ? '' : this.detail.audioDemoPath,
      deviceId: this.detail.deviceId,
      videoDemoPath: type === 'video' ? '' : this.detail.videoDemoPath,
    };
    type === 'video' ? (this.videoList = []) : (this.audioList = []);
    await putRtcDemo(data);
    await this.updateDeviceDetail();
  }
  async handleShowFile(path) {
    Preview.createModal({
      urls: path,
      ossBasePath: '/oss/iot/oss',
    });
  }
}
</script>

<style lang="less" module>
.deviceDebug {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .textBtn {
      color: var(--primary);
      cursor: pointer;
      margin-right: 18px;
    }
  }
  .block {
    margin-bottom: 20px;
  }
  .dsc {
    color: var(--font-info);
  }
  .input {
    width: 400px;
  }
}
.iconBox {
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 8px;
  background: var(--body-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 40px;
  }
}
.thumbnail {
  height: 40px;
  width: 40px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
}
</style>
